import * as Sentry from '@sentry/nuxt'

const config = useRuntimeConfig()
const isProd = config.public.appEnv === 'production'

Sentry.init({
  dsn: "https://6579b5d478c8c3cb27aea6b675ec20ee@o45513.ingest.us.sentry.io/4508138852515840",
  enabled: isProd,
  environment: config.public.appEnv,
  allowUrls: [/https?:\/\/((cdn|api)\.)?sp-selfservice\.com/],
  denyUrls: [/cdn-ukwest\.onetrust\.com/, /o2\.15gifts\.com/, /cdn\.nowinteract\.com/, /https?:\/\/www\.o2\.co\.uk/, /siteintercept\.qualtrics\.com/, /analytics/, /imp3\.nowinteract\.com/],
  ignoreErrors: [
    /amazon/i
  ],
})
